import React from 'react';
// Style Imports
import "./PatientFeedbackResources.scss"
// Content Imports
import Image from "@components/content/fractals-image.jsx"

const PatientFeedbackResources = () => {
	return (
		<section id="PatientFeedbackResources" className="bg-light-green">
            <div className="container">
                <div className='patient__image'>
                    <Image data={{desktopSrc: 'resources/patient-image.png', altText:"Image of Jennifer, started treatment in 2021"}} />
                </div>
                <div className='patient__feedback orange--quotes'>
                    <p>I wouldn't be here without the support of my <strong>Aurinia Alliance Nurse Case Manager.</strong> She gave me information about LUPKYNIS, answered my questions, and most importantly, coordinated with my insurance and my doctors to help get coverage for LUPKYNIS.</p>
                </div>
            </div>
        </section>
    );
};

export default PatientFeedbackResources;